<template>
  <div style="padding: 0px;">
    <van-nav-bar title="购买列表" :border="false" :fixed="true"/>
    <van-divider></van-divider>
    <div style="position: fixed;height: 100%; width: 100%;padding: 20px 0 80px;box-sizing: border-box">
<!--      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" style="height:100%;width:100%;overflow-y:auto;">-->
<!--      </van-pull-refresh>-->
      <van-list
          style="height:100%;width:100%; overflow-y:auto; padding-bottom: 20px; box-sizing: border-box;overflow-x: hidden"
          v-model="loading"
          :finished="finished"
          :error.sync="error"
          :offset="150"
          error-text="请求失败，点击重新加载"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div v-for="item in list" :key="item.id">
          <van-row gutter="15" @click="showItem(item)">
            <van-col span="24">
              <van-cell title="名称" :value="(item.fund_name === undefined ? 'null' : item.fund_name)"/>
            </van-col>
            <van-col span="24">
              <van-cell title="确认日期" :value="(item.confirmation_date === undefined ? '' : item.confirmation_date)"/>
            </van-col>
            <van-col span="24">
              <van-cell title="确认份额" :value="(item.confirmation_share === undefined ? '' : item.confirmation_share)"/>
            </van-col>
            <!--            <van-col span="24">-->
            <!--              <van-cell title="确认金额" :value="(item.confirmation_amount === undefined) ?'':item.confirmation_amount"/>-->
            <!--            </van-col>-->
            <!--            <van-col span="24">-->
            <!--              <van-cell title="份额净值" :value="(item.net_value === undefined) ?'':item.net_value"/>-->
            <!--            </van-col>-->
          </van-row>
          <van-divider></van-divider>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>

import store from "@/store";
import {Base64} from "js-base64";
import qs from "qs";

export default {
  name: 'Travel',
  data() {
    return {
      list: [],
      refreshing: false,
      loading: false,
      finished: false,
      error: false,
      page: 1
    }
  },
  mounted() {
    store.commit('toggleTabbarShow', true);
    // this.onLoad();
  },
  methods: {
    showItem (record) {
      console.log(record.product_id);
      this.$router.push({ path: '/myproduct/'+ record.product_id, query: { data: Base64.encode(qs.stringify(record))} })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = false;
      this.page = 1;
      this.onLoad();
    },
    onLoad() {
      this.$http.get("/customer/purchase_products/",{ page:this.page,page_size:10 })
          .then(res => {
            if (this.refreshing || this.page === 1) {
              this.list = [];
              this.refreshing = false;
            }
            // 加载状态结束
            this.loading = false;
            if (this.page>=res.data.page_count){
              // 数据全部加载完成
              this.finished = true;
            }else {
              this.finished = false;
              this.page += 1
            }
            console.log(res);
            if (res && res.code === 1000 && res.data.entries && res.data.entries.length>0) {
              this.list.push(...res.data.entries);
            }
          }).catch(err => {
            this.error = true
          })
    }
  },
}
</script>

<style>
.title {
  font-weight: 600;
  font-size: 18px;
}

/*.van-pull-refresh {*/
/*  height: 90%;*/
/*}*/

/*.van-pull-refresh /deep/ .van-pull-refresh__track {*/
/*  height: inherit;*/
/*}*/
</style>
